import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

import { Logger } from '../logger/logger.service';
import { CredentialsService } from '../authentication/credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class SupadminGuard implements CanActivate {
  constructor(
    private credentialsService: CredentialsService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.credentialsService.isAuthenticated() && this.credentialsService.getUserRole() == "ROLE_ADMIN") {
      return true;
    }
    if (this.credentialsService.isAuthenticated() && this.credentialsService.getUserRole() != "ROLE_ADMIN") {
      this.router.navigate(['/']);
      return false;
    }
    log.debug('Not authenticated, redirecting and adding redirect url...');
    
    this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
}
