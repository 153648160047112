import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
 import { LocalStorageService} from 'src/app/core/storage/localstorage.service';
 import { TranslateModule} from 'src/app/core/translations/translate.module';
 import { NotifyService } from 'src/app/core/notify/notify.service';
 import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
 import { LangService } from 'src/app/core/services/lan.service';
 import { TranslateService } from 'src/app/core/services/translate.service';
 import { ApiHttp } from 'src/app/core/http/api.http';


@NgModule({
  imports: [CommonModule, ToastrModule.forRoot()],
  providers: [
    NotifyService,
    ApiHttp,
    AuthenticationService,
    LocalStorageService,
    LangService,
    TranslateService,
    TranslateModule,
   // { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true }
  ],
  declarations: []
})
export class CoreModule {}
