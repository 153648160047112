import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from 'src/environments/environment';

if (environment.production) {
  enableProdMode();
}

console.log(
` /$$      /$$                               /$$ /$$                                               /$$           /$$
| $$$    /$$$                              |__/| $$                                              | $$          |__/
| $$$$  /$$$$  /$$$$$$  /$$$$$$$$  /$$$$$$  /$$| $$   /$$        /$$$$$$  /$$$$$$/$$$$   /$$$$$$ | $$  /$$$$$$  /$$
| $$ $$/$$ $$ /$$__  $$|____ /$$/ |____  $$| $$| $$  /$$/       /$$__  $$| $$_  $$_  $$ /$$__  $$| $$ /$$__  $$| $$
| $$  $$$| $$| $$  \ $$   /$$$$/   /$$$$$$$| $$| $$$$$$/       | $$$$$$$$| $$ \ $$ \ $$| $$  \ $$| $$| $$  \ $$| $$
| $$\  $ | $$| $$  | $$  /$$__/   /$$__  $$| $$| $$_  $$       | $$_____/| $$ | $$ | $$| $$  | $$| $$| $$  | $$| $$
| $$ \/  | $$|  $$$$$$/ /$$$$$$$$|  $$$$$$$| $$| $$ \  $$      |  $$$$$$$| $$ | $$ | $$| $$$$$$$/| $$|  $$$$$$/| $$
|__/     |__/ \______/ |________/ \_______/|__/|__/  \__/       \_______/|__/ |__/ |__/| $$____/ |__/ \______/ |__/
                                                                                       | $$                        
                                                                                       | $$                        
                                                                                       |__/                        `)

console.log('%cWelcome to Mozaïk!','font-size: 20px; font-weight: bold; color: rgba(123, 204, 243, 1)')
console.log('🔎 Find your dream job on: https://emploi.mozaiktalents.com/');


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
