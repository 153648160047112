import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule as TsModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LangService } from 'src/app/core/services/lan.service';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    HttpClientModule,
    TsModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [TsModule]
})
export class TranslateModule {
  constructor(private langService: LangService, private translate: TranslateService) {
    this.translate.setDefaultLang(this.langService.getLang());
    this.translate.use(this.langService.getLang());
    this.langService.setLocale();
  }
}
