import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  resourceName: string;

  constructor() {
    this.resourceName = environment.appName;
  }

  setItem(key: string, data: any) {
    key = this.resourceName + key.toLocaleUpperCase() + environment.version;
    localStorage.setItem(key, JSON.stringify(data));
  }

  removeItem(key) {
    key = this.resourceName + key.toLocaleUpperCase() + environment.version;
    localStorage.removeItem(key);
  }

  getItem(key) {
    key = this.resourceName + key.toLocaleUpperCase() + environment.version;
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
    return undefined;
  }

  clear() {
    localStorage.remove();
  }
}
