import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './core/storage/localstorage.service';

import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild
} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('myAnimation', [
      transition('* <=> *', [    
        query(':enter, :leave', style({ position: 'fixed', width:'100%' }), { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([ 
          query(':enter', [
            style({ opacity: '0' }),
            animate('0.5s ease-in-out', style({ opacity: '1' }))
          ], { optional: true }),
          query(':leave', [
            style({ opacity: '1' }),
            animate('0.5s ease-in-out', style({ opacity: '0' }))], { optional: true }),
        ]),
        query(':enter', animateChild(), { optional: true })
      ])
    ])  
  ]
})
export class AppComponent implements OnInit {

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    // event.preventDefault();
    this.localStorageService.removeItem("ville")
    // return false;
  }

  constructor(
              private titleService: Title, 
              private metaService: Meta, 
              private router: Router, 
              private activatedRoute: ActivatedRoute,
              private localStorageService: LocalStorageService){}

  getOutlet(o){
    return o.isActivated ? o.activatedRoute : '';
  }

  ngOnInit(){
    this.metaService.updateTag({
      name: 'description',
      content: "MOZAIK TALENTS met à la disposition des utilisateurs candidats différents services, en particulier un service de sélection d'offres d'emploi publiées par un recruteur par l'intermédiaire de la Plateforme au moyen d'un logiciel fonctionnant suivant un algorithme de \"matching\" opérant une sélection automatisée sur la base de divers critères",
    })

    if (!environment.production) {
      this.metaService.addTag(
        {
          name: 'robots',
          content: "noindex",
        }
      )
    }
    this.activatedRoute.queryParams.subscribe(routeParams => {
      // routeParams.status
      if(routeParams.ville) this.localStorageService.setItem("ville", routeParams.ville); 
    })
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`Mozaïk Talents - ${title}`);
        }
    });
  }
}
