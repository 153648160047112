import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiHttp {
  constructor(private http: HttpClient) { }

  get(path: string, params = null, options = null): Observable<any> {


    return params
      ? this.http.get(`${environment.apiUrl}${path}?${params}`, {
        observe: 'response',
      })
      : this.http.get(`${environment.apiUrl}${path}`, { observe: 'response', ...options });
  }

  put(path: string, body: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}${path}`, body, {
      observe: 'response',
    });
  }

  post(path: string, body: any, options = null): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, body, {
      observe: 'response',
      ...options
    });
  }

  delete(path, options = null): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${path}`, { observe: 'response', ...options });
  }

  uploadFile(path, file: File, name) {
    const formData: FormData = new FormData();
    formData.append(name, file);
    return this.http.post(`${environment.apiUrl}${path}`, formData);
  }

  downloadFileExcel(path, body?): Observable<Blob> {
    const parms = body !== undefined ? body : {};
    return this.http
      .post(`${environment.apiUrl}${path}`, parms, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => new Blob([res.body]), {
          type: 'application/vnd.ms-excel',
        })
      );
  }

  downloadFilePdf(path, body?): Observable<Blob> {
    const parms = body !== undefined ? body : {};
    return this.http
      .get(`${environment.apiUrl}${path}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => new Blob([res.body]), { type: 'application/pdf' })
      );
  }

  getLocation(latitude, longitude) {
    return this.http.get(
      'https://maps.googleapis.com/maps/api/geocode/v1/reverse?key=AIzaSyDdiPkx5aCu1UjBCjjksUNGG_4YxG2m3Kw&location=' +
      latitude +
      '%2C' +
      longitude +
      '&outFormat=json&thumbMaps=false'
    );
  }
}
