import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupadminGuard } from 'src/app/core/guards/supadmin.guard';

const routes: Routes = [
  // { path: '', redirectTo: '/', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('./modules/template/layout/home/home.module').then(
        (m) => m.HomeModule
      ),
    data: { title: 'Accueil' }
  },
  {
    path: '?utm_source=',
    loadChildren: () =>
      import('./modules/template/layout/home/home.module').then(
        (m) => m.HomeModule
      ),
  },

  {
    path: 'login',
    data: { showModal: false, title: 'Se connecter' },
    loadChildren: () =>
      import('./modules/features/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'register-candidat',
    loadChildren: () =>
      import('./modules/features/register-candidat/register-candidat.module').then(
        (m) => m.RegisterCandidatModule
      ),
    data: { title: 'Inscription candidat' }
  },
  {
    path: 'activation',
    data: { showActivationModal: true },
    loadChildren: () =>
      import('./modules/features/activation-account/activation-account.module').then(
        (m) => m.ActivationAccountModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./modules/features/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'candidat',
    loadChildren: () =>
      import('./modules/template/layout/candidate/candidate.module').then(
        (m) => m.CandidateModule
      ),
  },
  {
    path: 'recruteur',
    loadChildren: () =>
      import('./modules/template/layout/admin/admin.module').then(
        (m) => m.AdminModule
      ),
  },
  {
    canActivate: [SupadminGuard],
    path: 'supadmin',
    loadChildren: () => import('./modules/features/supadmin/supadmin.module').then(m => m.SupadminModule)
  },
  // { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
