import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocalStorageService } from '../storage/localstorage.service';
import { CredentialsService } from '../authentication/credentials.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private localStorage: LocalStorageService,
    private credentialsService: CredentialsService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (
      req.url.indexOf('/services/uaa/api/authenticate') == -1 &&
      req.url.indexOf('services/uaa/api/activate') == -1 &&
      req.url.indexOf('/services/main/api/candidats/bundle') == -1 &&
      req.url.indexOf('/services/main/api/candidats/register') == -1 &&
      req.url.indexOf('/services/main/api/upload/cvs?type=pdf') == -1 &&
      req.url.indexOf('/services/main/api/candidats/bundle-oauth') == -1 &&
      req.url.indexOf('/services/uaa/api/email-exist') == -1 &&
      req.url.indexOf('/services/uaa/api/reset') == -1 &&
      req.url.indexOf('/auth/login') == -1 &&
      req.url.indexOf('services/uaa/api/reset/finish') == -1 &&
      req.url.indexOf('services/uaa/api/reset') == -1 &&
      req.url.indexOf('services/main/api/offers/anonymous') == -1 &&
      req.url.indexOf('anonymous') == -1 &&
      req.url.indexOf('services/main/api/home-offers') == -1 &&
      req.url.indexOf('recruiters/create') == -1 &&
      req.url.indexOf('api/register') == -1 &&
      req.url.indexOf('api/jobsName') == -1 &&
      req.url.indexOf('api/OfferandoffreJson') == -1
    ) {

      if (this.credentialsService.isAuthenticated()) {
        const token = this.localStorage.getItem('token')?.access_token;
        if (token) {
          const AuthRequest = req.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token,
            },
          });
          return next.handle(AuthRequest);
        }
      }

      this.router.navigate(['/login']);
    }

    return next.handle(req);
  }
}
