import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/core/storage/localstorage.service';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
export interface ICredentials {
  userId: string;
  access_token: string;
  message: string;
  status: string;
  socialId: string
}

const credentialsKey = 'TOKEN';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: ICredentials | null = null;

  constructor(private localStorageService: LocalStorageService, private router: Router) {
    const savedCredentials = this.localStorageService.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = savedCredentials;
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    if (this.localStorageService.getItem(credentialsKey)) {
      const { access_token } = this.localStorageService.getItem(credentialsKey);
      let decoded: any = jwt_decode(access_token);
      if (decoded.exp < (new Date().getTime() + 1) / 1000) {
        this.localStorageService.removeItem(credentialsKey);
        return false;
      }
      return true;
    }
    return false;
  }

  isAdmin(): boolean {
    return true;
  }
  getUserRole() {
    const { access_token } = this.localStorageService.getItem(credentialsKey);
    let decoded: any = jwt_decode(access_token);
    if (decoded) {
      if (decoded.authorities.length == 0 && decoded.candidateId != null)
        return "ROLE_CANDIDAT";
    }
    if (decoded.authorities.length > 0) {
      return decoded.authorities[0].authority;
    }
    else {
      this.localStorageService.removeItem(credentialsKey);
      this.router.navigate(['/login']);
    }

  }
  getUserId() {
    if (this.localStorageService.getItem(credentialsKey)) {
      const { access_token } = this.localStorageService.getItem(credentialsKey);
      let decoded: any = jwt_decode(access_token);


      if (decoded == null || decoded == undefined) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      if (decoded.userId == null) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      if (decoded.exp < (new Date().getTime() + 1) / 1000) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      return decoded.userId;
    }
    this.localStorageService.removeItem(credentialsKey);
    this.router.navigate(['/login']);

    return null;
  }
  getCandidatId() {
    if (this.localStorageService.getItem(credentialsKey)) {
      const { access_token } = this.localStorageService.getItem(credentialsKey);
      let decoded: any = jwt_decode(access_token);


      if (decoded == null || decoded == undefined) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      if (decoded.candidateId == null) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      if (decoded.exp < (new Date().getTime() + 1) / 1000) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      return decoded.candidateId;
    }
    this.localStorageService.removeItem(credentialsKey);
    this.router.navigate(['/login']);

    return null;
  }
  getRecuiterId() {
    if (this.localStorageService.getItem(credentialsKey)) {
      const { access_token } = this.localStorageService.getItem(credentialsKey);
      let decoded: any = jwt_decode(access_token);
      if (decoded == null || decoded == undefined) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      if (decoded.userId == null) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      if (decoded.exp < (new Date().getTime() + 1) / 1000) {
        this.localStorageService.removeItem(credentialsKey);
        this.router.navigate(['/login']);
      }
      return {
        userId: decoded.userId,
        recuiterId: decoded.recruiterId,
        entrepriseId: decoded.entrepriseId,
        company: decoded.companyName,
        jti: decoded.jti,
        firstName: decoded.firstName,
        lastName: decoded.lastName,
        email: decoded.user_name,
        isRecruiterValid: decoded.isRecruiterValid
      };
    }
    this.localStorageService.removeItem(credentialsKey);
    this.router.navigate(['/login']);

    return null;
  }
  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): ICredentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: ICredentials) {
    this._credentials = credentials || null;

    if (credentials) {
      this.localStorageService.setItem(credentialsKey, credentials);
    } else {
      this.localStorageService.removeItem(credentialsKey);
      this.localStorageService.removeItem("experience-list");
      this.localStorageService.removeItem("certif-list");
    }
  }

  getCredentials(): ICredentials | null {
    if (!this._credentials) {
      this._credentials = this.localStorageService.getItem(credentialsKey);
      return this._credentials;
    }
    return this._credentials;
  }
}
