import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotifyService } from '../notify/notify.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../storage/localstorage.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private notifyService: NotifyService, private router: Router, private localStorageService: LocalStorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }
  // Customize the default error handler here if needed
  private errorHandler(response: any): Observable<HttpEvent<any>> {
    if (response.status == 401 && this.localStorageService.getItem("token")) {
      this.localStorageService.removeItem("token");
      this.router.navigate(['/login']);
    }
    throw response;
  }
}
